$theme-colors: (
    "greenish": #7fb11d,
    "lightgray": #7f8980
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family:'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #03242b;
  font-size: 16px;
}

code {
  font-family: 'Gilroy', sans-serif;
}

section{
  padding: 90px 0;
}

button{
  background: #fbd94f;
  color: #03242b;
  border: 0;
  border-radius: 30px;
  padding: 12px 36px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover{
    transition: all 0.3s ease;
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
}

.row{
  margin-right: -40px !important;
  margin-left: -40px !important;
  @media screen and (max-width:992px){
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
}
.col-lg-6, .col-lg-4{
  padding-left: 40px !important;
  padding-right: 40px !important;
  @media screen and (max-width:992px){
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.slick-track{
  display: flex !important;
}
.slick-list{
  overflow-x: hidden;
}
.slick-arrow{
  display: none !important;
}
.top{
  h3{
    font-size: 36px;
    @media screen and (max-width:992px){
      font-size: 25px;
    }
  }
}

.container{
  @media screen and (max-width:992px){
    max-width: unset;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.row{
  @media screen and (max-width:500px){
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}